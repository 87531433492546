<template>
  <v-container>
    <v-row class="mx-auto">
      <v-col cols="auto">
        <v-card class="mx-auto my-12" max-width="400">
          <v-img height="250" :src="chameleon"></v-img>

          <v-card-title>Chameleon</v-card-title>

          <v-card-text>
            <div class="text-justify">
              Web version of the popular board game Chameleon! Host your own game and play with at least 2 players. Many will know the word, one will not.
            </div>
          </v-card-text>

          <v-divider class="mx-4"></v-divider>

          <v-card-text>
            VueJS, Express, socket.io
          </v-card-text>

          <v-card-actions class="text-center">
            <v-btn class="font-weight-bold indigo--text" @click.prevent="openTab('https://c.mobis.dev')">
              Launch
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="auto">
        <v-card class="mx-auto my-12" max-width="400">
          <v-img height="250" :src="trivia"></v-img>

          <v-card-title>Trivia!</v-card-title>

          <v-card-text>
            <div class="text-justify">
              Host your own live multiple-choice trivia game! Features include real-time answer selections and leaderboard. Plus a consolation winner!
            </div>
          </v-card-text>

          <v-divider class="mx-4"></v-divider>

          <v-card-text>
            VueJS, Express, socket.io
          </v-card-text>

          <v-card-actions class="text-center">
            <v-btn class="font-weight-bold indigo--text" @click.prevent="openTab('https://trivia.mobis.dev')">
              Launch
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import chameleon from "../assets/chameleon.png";
import trivia from "../assets/trivia.png";

export default {
  name: "Projects",

  data: () => ({
    chameleon,
    trivia,
  }),

  methods: {
    openTab: (url) => {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.indigo--text {
  color: #7986cb;
  border-radius: 0;
}
</style>
