<template>
  <div class="home">
    <p><a href="https://github.com/mobidots/" target="_blank">GitHub</a></p>
    <p><a href="https://www.linkedin.com/in/mubashirsaad/" target="_blank">LinkedIn</a></p>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
